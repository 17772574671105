
import { defineComponent } from "vue";
import KTActivityItem1 from "./activity-timeline/Item1.vue";
import moment from "moment";

import { Form, Field, ErrorMessage } from "vee-validate";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "activity",
  components: {
    KTActivityItem1,
    Field,
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const store = useStore();
    const roles = store.getters?.currentUser?.roles?.map((a) => a.name);

    const isAdmin = roles?.includes("admin");
    const isFA = roles?.includes("financial-advisor");
    const isViewer = roles?.includes("viewer");
    const isBranchHead = roles?.includes("branch-head");

    return {
      sessionDateTime: moment().format("YYYY-MM-DD hh:mm:ss"),
      store: useStore(),
      route,
      router,
      cstmr_id,
      isNewAppointment: false,
      isAdmin,
      isFA,
      isViewer,
      isBranchHead
    };
  },
  methods: {
    init() {
      let customer = this.store.getters.getCustomerData;
      if (customer.appointments.length > 0) {
        for (const key in customer.appointments) {
          if (customer.appointments[key].status == "New") {
            this.isNewAppointment = true;
            break;
          }
        }
      }
    },
    today() {
      return moment().format("DD MMMM YYYY, dddd");
    },
    onStartNewSession() {
      Swal.fire({
        text: "Creating New Session",
        icon: "info",
        buttonsStyling: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      const user = this.store.getters.getProfile;
      let momentDate = moment(
        this.sessionDateTime,
        "YYYY-MM-DD hh:mm:ss"
      ).toDate();

      let appointment = {
        fa_id: user.id,
        user_id: this.cstmr_id,
        method_id: 3,
        service_id: "18",
        organisation_id: user.organisation_id,
        appointment_date: moment(momentDate).format("YYYY-MM-DD"),
        slot_type: "appointment",
        start_time: moment(momentDate).format("hh:mm:ss"),
        end_time: "00:00:00",
        status: "New",
        fa_reference: "Ad hoc Session",
      } as any;

      console.log(user);
      console.log(appointment);

      //Create New Session
      this.store.dispatch(Actions.STORE_APPOINTMENT, appointment).then(() => {
        let data = this.store.getters.getAppointmentData.data;
        appointment.slot_id = data.id;

        //Store Appointment Raw
        this.store
          .dispatch(Actions.STORE_RAW_APPOINTMENT, {
            type: "appointment",
            user_id: this.cstmr_id,
            raw: appointment,
          })
          .then(() => {
            let slot = appointment;
            slot.raw = {};
            slot.status = "In Progress";
            slot.fa_id = user.id;
            slot.id = data.id;

            this.store.dispatch(Actions.UPDATE_SLOT, slot).then(() => {
              let slot_log = {
                slot_id: slot.id,
                type: "Updated Appointment Status",
                raw: {
                  status: slot.status,
                },
                user_id: slot.fa_id,
              };

              this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
                Swal.fire({
                  title: "Congratulation!",
                  text: "Session has started!",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                }).then(() => {
                  window.location.href =
                    "#/customers/details/" +
                    this.cstmr_id +
                    "/activity/" +
                    data.id;
                  window.location.reload();
                });
              });
            });
          });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
