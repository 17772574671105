
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.core.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default defineComponent({
  name: "item-1",
  components: {
    Field,
    Form,
    ErrorMessage,
    QuillEditor,
  },
  setup(props, { emit }) {
    const formVCLinkValidator = Yup.object({
      vc: Yup.object({
        link: Yup.string()
          .required()
          .label("Please insert the Video Conference Link"),
        email: Yup.string()
          .email()
          .required("Please enter a valid Email")
          .label("Email"),
      }),
    });
    return {
      formVCLinkValidator,
    };
  },
  data() {
    const route = useRoute();
    const isBranches = true;
    const isVirtualOffice = false;
    const appointment_id = route.params.appointment_id ?? null;
    const update_appointment = {
      details: {
        // date: "",
        // time: "",
        type_of_enquiries: "",
        sub_categories: "",
        ge_categories: "",
        type_of_customer: "",
        type_of_enquiry: "",
        post_dmp_enquiry: "",
        type_of_financial_skills_hh: "",
        type_of_financial_skills_bus: "",
        type_of_bus: "",
        type_of_financial_resolution_hh: "",
        type_of_financial_resolution_bus: "",
        additional_advisory: "",
        type_of_lenders: "",
        business_categories: "",
        advisor_note: "",
        types_of_sources: "",
        types_of_sources_appointment: "",
        types_of_sources_advisory_office: "",
        types_of_sources_colla_events: "",
      },
      vc: {
        link: "",
        email: "",
        advisor_note: "",
      },
      phone: {
        result: "",
        remarks: "",
        reason: "",
        advisor_note: "",
      },
      join: {
        dmpCheck: false,
        sdrsCheck: false,
        myKNPCheck: false,
        sdrsIsMultiLender: false,
        advisor_note: "",
      },
    };

    const re_assign = {
      organisation_id: null,
      fa_id: null,
    };

    const typeOfEnquiries = [
      {
        value: "Personalised Financial Plan (PFP)",
        label: "Personalised Financial Plan (PFP)",
      },
      {
        value: "Urus Help",
        label: "Urus Help",
      },
      {
        value: "Financial Skills",
        label: "Financial Skills",
      },
      {
        value: "Financial Resolution",
        label: "Financial Resolution",
      },
      {
        value: "Financial Planning",
        label: "Financial Planning",
      },
      {
        value: "Financial Scoring",
        label: "Financial Scoring",
      },
      {
        value: "Reverse Mortgage (Skim Saraan Bercagar - SSB)",
        label: "Reverse Mortgage (Skim Saraan Bercagar - SSB)",
      },
      {
        value: "Financial Advisory Services (MyKNP)",
        label: "Financial Advisory Services (MyKNP)",
      },
      {
        value: "General Enquiry",
        label: "General Enquiry",
      },
    ];

    const catGEs = [
      {
        value: "Household",
        label: "Household",
      },
      {
        value: "Business (SME)",
        label: "Business (SME)",
      },
    ];

    const typeOfCustomer = [
      {
        value: "New Customer",
        label: "New Customer",
      },
      {
        value: "Existing Customer",
        label: "Existing Customer",
      },
    ];

    const typeOfEnquiryNewCustomerHH = [
      { label: "CCRIS Report/eCCRIS", value: "CCRIS Report/eCCRIS" },
      {
        label: "Personalized Financial Plan",
        value: "Personalized Financial Plan",
      },
      { label: "URUS", value: "URUS" },
    ];

    const typeOfEnquiryExistingCustomerHH = [
      { value: "CCRIS Report/eCCRIS", label: "CCRIS Report/eCCRIS" },
      // {
      //   value: "Personalized Financial Plan",
      //   label: "Personalized Financial Plan",
      // },
      // { value: "URUS", label: "URUS" },
      // { value: "FIRST", label: "FIRST" },
      // { value: "Social Synergy", label: "Social Synergy" },
      // {
      //   value: "Housing Loan/Home Financing",
      //   label: "Housing Loan/Home Financing",
      // },
      // { value: "Hire Purchase", label: "Hire Purchase" },
      // {
      //   value: "Personal Loan/Personal Financing",
      //   label: "Personal Loan/Personal Financing",
      // },
      // { value: "Overdraft", label: "Overdraft" },
      // { value: "Cash Flow", label: "Cash Flow" },
      // { value: "Budgeting", label: "Budgeting" },
      // { value: "Saving", label: "Saving" },
      // { value: "Emergency Fund", label: "Emergency Fund" },
      // { value: "Expenses", label: "Expenses" },
      // {
      //   value: "Borrowing Basics - Credit Assessment",
      //   label: "Borrowing Basics - Credit Assessment",
      // },
      // {
      //   value: "Borrowing Basics - Credit Scores",
      //   label: "Borrowing Basics - Credit Scores",
      // },
      // {
      //   value: "Borrowing Basic - Debt Service Ratio",
      //   label: "Borrowing Basic - Debt Service Ratio",
      // },
      // {
      //   value: "Recovery Action, Bankruptcy and Property Foreclosure",
      //   label: "Recovery Action, Bankruptcy and Property Foreclosure",
      // },
      // {
      //   value: "Banking Products and Services Conventional & Islamic",
      //   label: "Banking Products and Services Conventional & Islamic",
      // },
      // {
      //   value: "Banking Principles Conventional and Islamic",
      //   label: "Banking Principles Conventional and Islamic",
      // },
      // {
      //   value: "Basic Principles of Insurance & Takaful",
      //   label: "Basic Principles of Insurance & Takaful",
      // },
      // { value: "General Investment", label: "General Investment" },
      // { value: "Financial Scams", label: "Financial Scams" },
      // { value: "Complaint Management", label: "Complaint Management" },
      { value: "Post DMP", label: "Post DMP" },
    ];

    const typeOfEnquiryNewCustomerBus = [
      {
        value: "Small Debt Resolution Scheme (SDRS)",
        label: "Small Debt Resolution Scheme (SDRS)",
      },
      { value: "Repayment Assistance", label: "Repayment Assistance" },
    ];

    const typeOfEnquiryExistingCustomerBus = [
      {
        value: "Small Debt Resolution Scheme (SDRS)",
        label: "Small Debt Resolution Scheme (SDRS)",
      },
      { value: "Repayment Assistance", label: "Repayment Assistance" },
    ];

    const postDmpEnquiries = [
      { value: "Termination", label: "Termination" },
      { value: "Settlement", label: "Settlement" },
      { value: "Withdrawal", label: "Withdrawal" },
      { value: "Reschedule", label: "Reschedule" },
      { value: "Lapsed", label: "Lapsed" },
      { value: "BLD expired", label: "BLD expired" },
      { value: "Cancel Counselling", label: "Cancel Counselling" },
      { value: "DMP Completed", label: "DMP Completed" },
      { value: "Tenure Completed", label: "Tenure Completed" },
      { value: "Postponement", label: "Postponement" },
      { value: "Account Enquiry", label: "Account Enquiry" },
    ];

    const subCatFinancialSkills = [
      {
        value: "Household",
        label: "Household",
      },
      {
        value: "Business (SME)",
        label: "Business (SME)",
      },
    ];

    const subCatFinancialReso = [
      {
        value: "Household",
        label: "Household",
      },
      {
        value: "Business (SME)",
        label: "Business (SME)",
      },
    ];

    const subCatFinancialPlan = [
      {
        value: "Household",
        label: "Household",
      },
    ];

    const subCatFinancialScore = [
      {
        value: "Household",
        label: "Household",
      },
    ];

    const typeOfFinancialSkillsHh = [
      { value: "Reduce Loan Commitment", label: "Reduce Loan Commitment" },
      { value: "Cash Flow Management", label: "Cash Flow Management" },
      { value: "Basic Borrowings", label: "Basic Borrowings" },
      { value: "Budgeting", label: "Budgeting" },
      { value: "Savings", label: "Savings" },
      { value: "New Loan Application", label: "New Loan Application" },
      {
        value:
          "Recovery Action, Legal Action, Bankruptcy and Property Foreclosure",
        label:
          "Recovery Action, Legal Action, Bankruptcy and Property Foreclosure",
      },
      { value: "Mule Account", label: "Mule Account" },
      { value: "Emergency Fund", label: "Emergency Fund" },
      {
        value: "Banking Products and Services Conventional & Islamic",
        label: "Banking Products and Services Conventional & Islamic",
      },
      {
        value: "Banking Principles Conventional and Islamic",
        label: "Banking Principles Conventional and Islamic",
      },
      {
        value: "Basic Principles of Insurance & Takaful",
        label: "Basic Principles of Insurance & Takaful",
      },
    ];

    const typeOfFinancialSkillsBus = [
      {
        value: "Financial Landscape (Bijak Wang Bijak Niaga, BWBN)",
        label: "Financial Landscape (Bijak Wang Bijak Niaga, BWBN)",
      },
      { value: "E-Payment", label: "E-Payment" },
      {
        value: "Ethics & Values in Business",
        label: "Ethics & Values in Business",
      },
      { value: "Financial Scam", label: "Financial Scam" },
    ];

    const typeOfBus = [
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Partnership", label: "Partnership" },
      { value: "Sdn Bhd", label: "Sdn Bhd" },
    ];

    const typeOfFinancialResolutionHH = [
      {
        value: "Advisory on DMP",
        label: "Advisory on DMP",
      },
    ];

    const additionalAdvisories = [
      {
        value: "Social Synergy",
        label: "Social Synergy",
      },
    ];

    const typeOfFinancialResolutionBus = [
      {
        value: "Small Debt Resolution Scheme (SDRS)",
        label: "Small Debt Resolution Scheme (SDRS)",
      },
      {
        value: "Repayment Assistance",
        label: "Repayment Assistance",
      },
    ];

    const typeOfLenders = [
      { value: "Single", label: "Single" },
      { value: "Multiple", label: "Multiple" },
    ];

    const businessCategories = [
      { value: "Wound up", label: "Wound up" },
      {
        value:
          "Any one of the owners/directors/shareholders who is adjudicated a bankrupt",
        label:
          "Any one of the owners/directors/shareholders who is adjudicated a bankrupt",
      },
      {
        value: "Receivers & Manager appointed",
        label: "Receivers & Manager appointed",
      },
      {
        value: "Judicial manager appointed",
        label: "Judicial manager appointed",
      },
    ];

    const locations = [
      {
        value: "1",
        label: "Branch",
      },
      {
        value: "2",
        label: "Advisory Office",
      },
    ];

    const methods = [
      {
        value: "Walk-In",
        label: "Walk-In",
      },
      {
        value: "Phone",
        label: "Phone",
      },
      {
        value: "Video Conference",
        label: "Video Conference",
      },
    ];

    const statuses = [
      {
        value: "New",
        label: "New",
      },
      {
        value: "In Progress",
        label: "In Progress",
      },
      {
        value: "Close",
        label: "Close",
      },
    ];

    const callResult = [
      {
        value: "Contactable",
        label: "Contactable",
      },
      {
        value: "Uncontactable",
        label: "Uncontactable",
      },
    ];

    const actionRemarksUnCon = [
      {
        value: "Number is not reachable",
        label: "Number is not reachable",
      },
      {
        value: "Number is not in service",
        label: "Number is not in service",
      },
    ];

    const actionRemarksCon = [
      {
        value: "Not ready to make decision",
        label: "Not ready to make decision",
      },
      {
        value: "Not Interested",
        label: "Not Interested",
      },
      {
        value: "Want to Self Manage",
        label: "Want to Self Manage",
      },
      {
        value: "Name will be blacklisted",
        label: "Name will be blacklisted",
      },
      {
        value: "Inability to apply for a new loan",
        label: "Inability to apply for a new loan",
      },
      {
        value: "Moratorium approved by the Bank",
        label: "Moratorium approved by the Bank",
      },
      {
        value: "DMP Enrollment",
        label: "DMP Enrollment",
      },
      {
        value: "Advisory",
        label: "Advisory",
      },
      {
        value: "Post DMP",
        label: "Post DMP",
      },
    ];

    const dmp_reason = [
      {
        value: "High Loan Commitment",
        label: "High Loan Commitment",
      },
      {
        value: "Holistic Loan Restructuring",
        label: "Holistic Loan Restructuring",
      },
      {
        value: "Non-Performing Loan",
        label: "Non-Performing Loan",
      },
      {
        value: "The Financial situation has improved",
        label: "The Financial situation has improved",
      },
      {
        value: "FIs Special Repayment Package",
        label: "FIs Special Repayment Package",
      },
      {
        value: "Remove CCRIS tagging",
        label: "Remove CCRIS tagging",
      },
      {
        value: "Apply for a new loan application",
        label: "Apply for a new loan application",
      },
      {
        value: "Reschedulement (Include new facility)",
        label: "Reschedulement (Include new facility)",
      },
      {
        value: "Reschedulement (Increase DMP Instalment)",
        label: "Reschedulement (Increase DMP Instalment)",
      },
      {
        value: "Reschedulement (Decrease DMP Instalment)",
        label: "Reschedulement (Decrease DMP Instalment)",
      },
      {
        value: "Amend Loan Details",
        label: "Amend Loan Details",
      },
      {
        value: "Amended required to comply parameter",
        label: "Amended required to comply parameter",
      },
      {
        value: "Change Matrix",
        label: "Change Matrix",
      },
      {
        value: "Change of Creditor",
        label: "Change of Creditor",
      },
      {
        value: "Change of Interest Rate",
        label: "Change of Interest Rate",
      },
      {
        value: "Change of Outstanding Balance",
        label: "Change of Outstanding Balance",
      },
      {
        value: "Medical Reason",
        label: "Medical Reason",
      },
      {
        value: "Reduction in Income",
        label: "Reduction in Income",
      },
      {
        value: "Increase in Income",
        label: "Increase in Income",
      },
    ];

    const advisors = [];

    const branches = [];

    const advisoryOffices = [];

    const typesOfSources = [
      { value: "Appointment", label: "Appointment" },
      { value: "Customer Walk In", label: "Customer Walk In" },
      { value: "Customer Call Branch", label: "Customer Call Branch" },
      // { value: "Fi Referral", label: "Fi Referral" },
      { value: "Advisory office", label: "Advisory office" },
      { value: "Collaboration Events", label: "Collaboration Events" },
      { value: "FI Linkage", label: "FI Linkage" },
      { value: "PARAS", label: "PARAS" },
      { value: "2CP", label: "2CP" },
      { value: "Excess Payment", label: "Excess Payment" },
    ];

    const typesOfSourcesAppointment = [
      { value: "Branch", label: "Branch" },
      { value: "Phone", label: "Phone" },
      { value: "Video Conferencing", label: "Video Conferencing" },
      { value: "Advisory Office", label: "Advisory Office" },
      // { value: "Virtual Advisory", label: "Virtual Advisory" },
    ];

    const typesOfSourcesAdvisoryOffice = [
      { value: "Seremban", label: "Seremban" },
      { value: "Shah Alam", label: "Shah Alam" },
      { value: "KWSP Seberang Jaya", label: "KWSP Seberang Jaya" },
      {
        value: "Wisma Persekutuan Taiping",
        label: "Wisma Persekutuan Taiping",
      },
      { value: "Maybank Prima Sandakan", label: "Maybank Prima Sandakan" },
      { value: "Miri", label: "Miri" },
      { value: "Sibu", label: "Sibu" },
      { value: "Temerloh", label: "Temerloh" },
      { value: "Tanah Merah", label: "Tanah Merah" },
      { value: "Kemaman", label: "Kemaman" },
    ];

    const banks = [];

    const customer = {
      email: "",
      appointments: [] as any,
      user_raws: [] as any,
    };
    const router = useRouter();
    const store = useStore();
    const cstmr_id = route.params.id ?? null;

    const callLog = {
      status: "",
      remarks: "",
      reason: "",
    };

    const vc = {
      link: "",
      email: "",
    };

    const roles = store.getters?.currentUser?.roles?.map((a) => a.name);

    const isAdmin = roles?.includes("admin");
    const isViewer = roles?.includes("viewer");
    const isFA = roles?.includes("financial-advisor");
    const isBranchHead = roles?.includes("branch-head");

    return {
      store: useStore(),
      moment: moment,
      user: {} as any,
      appointment_id,
      roles,
      isAdmin,
      isFA,
      isViewer,
      isBranchHead,
      isBranches,
      isVirtualOffice,
      methods,
      callResult,
      statuses,
      advisors,
      branches,
      advisoryOffices,
      actionRemarksUnCon,
      actionRemarksCon,
      dmp_reason,
      re_assign,
      locations,
      cstmr_id,
      customer,
      appReady: false,
      callLog,
      vc,
      update_appointment,
      typeOfEnquiries,
      catGEs,
      subCatFinancialSkills,
      subCatFinancialReso,
      subCatFinancialPlan,
      subCatFinancialScore,
      typeOfFinancialSkillsHh,
      typeOfFinancialSkillsBus,
      typeOfBus,
      typeOfFinancialResolutionHH,
      typeOfFinancialResolutionBus,
      typeOfLenders,
      businessCategories,
      additionalAdvisories,
      typeOfCustomer,
      typeOfEnquiryNewCustomerHH,
      typeOfEnquiryExistingCustomerHH,
      postDmpEnquiries,
      typeOfEnquiryNewCustomerBus,
      typeOfEnquiryExistingCustomerBus,
      typesOfSources,
      typesOfSourcesAppointment,
      typesOfSourcesAdvisoryOffice,
      banks,
      financialResolution: [5, 6, 7, 8, 9, 10],
      isAppointmentsReady: false,
      editorContent: "",
      editorOptions: {
        // Add the 'modules' object if you want to enable modules
        // For example, 'toolbar' module allows you to add formatting options
        modules: {
          toolbar: [
            // Add your desired formatting options here
            ["bold", "italic", "underline", "strike"],
          ],
        },
        theme: "snow",
      },
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });

      this.store.dispatch(Actions.GET_FA_OFFICES).then(() => {
        this.advisoryOffices = this.store.getters.getAllAdvisoryOfficessData;
      });

      this.store.dispatch(Actions.GET_CREDITORS).then(() => {
        this.banks = this.store.getters.getCreditorsData;
      });

      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        this.user = this.store.getters.getProfile;

        this.customer = this.store.getters.getCustomerData;
        this.update_appointment.vc.email = this.customer.email;
      });

      setTimeout(() => {
        this.isAppointmentsReady = true;
      }, 2000);
    },
    getHumanDate(date, dateOnly = false) {
      if (dateOnly) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return moment(date).format("DD/MM/YYYY h:mm:ss a");
      }
    },
    onChangeTypeOfEnquiries(index) {
      this.customer.appointments[index].isJoinMyKNP = false;
      this.customer.appointments[index].isJoinDMP = false;
      this.customer.appointments[index].isJoinSDRS = false;

      if (
        this.update_appointment.details.type_of_enquiries ==
        "Financial Resolution"
      ) {
        this.customer.appointments[index].isJoinDMP = true;
        this.customer.appointments[index].isJoinSDRS = true;
      } else if (
        this.update_appointment.details.type_of_enquiries ==
        "Financial Advisory Services (MyKNP)"
      ) {
        this.customer.appointments[index].isJoinMyKNP = true;
      }
    },
    onUpdateAppointment(index) {
      let service = this.update_appointment.details.type_of_enquiries;
      let subService = this.update_appointment.details.sub_categories;
      let FinResoBusType = this.update_appointment.details
        .type_of_financial_resolution_bus;
      let geType = this.update_appointment.details.type_of_enquiry;
      let slot = this.customer.appointments[index];
      let slot_log = {
        slot_id: slot.id,
        type: "Updated Appointment Details",
        raw: this.update_appointment.details,
        user_id: slot.fa_id,
      };

      if (service) {
        slot.latest_service_id = this.getServiceId(service);

        if (this.getServiceId(service) == "2") {
          if (subService == "Household") {
            slot.latest_service_id = "3";
          } else if (subService == "Business (SME)") {
            slot.latest_service_id = "4";
          }
        } else if (this.getServiceId(service) == "5") {
          if (subService == "Household") {
            slot.latest_service_id = "6";
          } else if (subService == "Business (SME)") {
            slot.latest_service_id = "7";
            if (FinResoBusType == "Small Debt Resolution Scheme (SDRS)") {
              slot.latest_service_id = "9";
            } else if (FinResoBusType == "Repayment Assistance") {
              slot.latest_service_id = "10";
            }
          }
        } else if (this.getServiceId(service) == "18") {
          if (geType == "CCRIS Report/eCCRIS") {
            slot.latest_service_id = "21";
          } else if (geType == "Personalized Financial Plan") {
            slot.latest_service_id = "22";
          } else if (geType == "URUS") {
            slot.latest_service_id = "23";
          } else if (geType == "Post DMP") {
            slot.latest_service_id = "24";
          } else if (geType == "Small Debt Resolution Scheme (SDRS)") {
            slot.latest_service_id = "25";
          } else if (geType == "Repayment Assistance") {
            slot.latest_service_id = "26";
          }
        }

        this.store.dispatch(Actions.UPDATE_SLOT, slot).then(() => {
          this.storeSlotLog(slot_log, index);
        });
      } else {
        this.storeSlotLog(slot_log, index);
      }
    },

    storeSlotLog(slot_log, index) {
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        if (this.update_appointment.join.dmpCheck) {
          setTimeout(() => {
            this.onJoinDMP(index);
          }, 1000);
        } else if (this.update_appointment.join.sdrsCheck) {
          setTimeout(() => {
            this.onJoinSDRS(index);
          }, 1000);
        } else if (this.update_appointment.join.myKNPCheck) {
          setTimeout(() => {
            this.onJoinMyKNP(index);
          }, 1000);
        } else {
          Swal.fire({
            title: "Congratulation!",
            text: "Appointment Details has been updated!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    },
    onSelectLocation(e) {
      if (e == 1) {
        this.isBranches = true;
      } else {
        this.isBranches = false;
      }

      if (e == 3) {
        this.isVirtualOffice = true;
      } else {
        this.isVirtualOffice = false;
      }
    },
    onEmailVCLink(index) {
      let slot = this.customer.appointments[index];
      let slot_log = {
        slot_id: slot.id,
        type: "Sent VC Link",
        raw: {
          link: this.update_appointment.vc.link,
          email: this.update_appointment.vc.email,
          advisor_note: this.update_appointment.vc.advisor_note,
        },
        user_id: slot.fa_id,
      };
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "VC Link session has been sent!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      });
    },
    onStartSession(index) {
      if (this.customer.appointments[index]["method_id"] == 2) {
        if (this.customer.appointments[index]["slot_logs"].length > 0) {
          this.startSession(index);
        } else {
          Swal.fire({
            title: "Are you sure?",
            text:
              "VC Link is not provided to the customer. Are your sure want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Proceed",
          }).then((value) => {
            if (value.isConfirmed) {
              this.startSession(index);
            }
          });
        }
      } else {
        this.startSession(index);
      }
    },
    startSession(index) {
      const app_logs = [
        { status: "Start", start_date: moment().format("DD/MM/YYYY HH:mm:ss") },
      ];

      this.customer.appointments[index].raw["app_logs"] = app_logs;

      let slot = this.customer.appointments[index];
      slot.raw = {};
      slot.status = "In Progress";
      slot.fa_id = this.user.id;

      this.store.dispatch(Actions.UPDATE_SLOT, slot).then(() => {
        let slot_log = {
          slot_id: slot.id,
          type: "Updated Appointment Status",
          raw: {
            status: slot.status,
          },
          user_id: slot.fa_id,
        };

        this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
          Swal.fire({
            title: "Congratulation!",
            text: "Appointment session has started!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        });
      });
    },
    onCaseResolved(index) {
      let slot = this.customer.appointments[index];
      slot.raw = {};
      slot.status = "Case Resolved";

      this.store.dispatch(Actions.UPDATE_SLOT, slot).then(() => {
        let slot_log = {
          slot_id: slot.id,
          type: "Updated Appointment Status",
          raw: {
            status: slot.status,
          },
          user_id: slot.fa_id,
        };
        this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
          Swal.fire({
            title: "Congratulation!",
            text: "Case has been resolved!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        });
      });
    },
    onCaseClosed(index) {
      let slot = this.customer.appointments[index];
      slot.raw = {};
      slot.status = "Case Closed";

      this.store.dispatch(Actions.UPDATE_SLOT, slot).then(() => {
        let slot_log = {
          slot_id: slot.id,
          type: "Updated Appointment Status",
          raw: {
            status: slot.status,
          },
          user_id: slot.fa_id,
        };
        this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
          Swal.fire({
            title: "Congratulation!",
            text: "Case has been closed!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        });
      });
    },
    onSubmitCallLog(index) {
      let slot = this.customer.appointments[index];
      let slot_log = {
        slot_id: slot.id,
        type: "Call Logs",
        raw: {
          result: this.update_appointment.phone.result,
          remarks: this.update_appointment.phone.remarks,
          reason: this.update_appointment.phone.reason,
          advisor_note: this.update_appointment.phone.advisor_note,
        },
        user_id: slot.fa_id,
      };
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Call Log has been updated!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      });
    },
    onSelectBranch(e) {
      let orgId = e;
      this.getAdvisor(orgId);
    },
    onSelectOffice(e) {
      let orgId = e;
      this.getAdvisor(orgId);
    },
    getAdvisor(orgId) {
      this.store.dispatch(Actions.GET_ADVISOR, orgId).then(() => {
        this.advisors = this.store.getters.getAdvisorsData;
      });
    },
    onSubmitReAssign(index) {
      let slot = this.customer.appointments[index];
      slot.raw = {};
      slot.fa_id = this.re_assign.fa_id;
      slot.organisation_id = this.re_assign.organisation_id;

      this.store.dispatch(Actions.UPDATE_SLOT, slot).then(() => {
        let slot_log = {
          slot_id: slot.id,
          type: "Re-Assign Advisor",
          raw: {
            advisor: this.re_assign.fa_id,
          },
          user_id: this.user.id,
        };
        this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
          Swal.fire({
            title: "Congratulation!",
            text: "Appointment has been re-assigned!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        });
      });
    },
    onJoinDMP(index) {
      let slot = this.customer.appointments[index];
      let slot_log = {
        slot_id: slot.id,
        type: "Customer Agree to Join DMP",
        raw: {
          date: "",
          time: "",
          service: "",
          sub_service: "",
          gen_enq_cat: "",
          gen_enq: "",
          advisor_note: this.update_appointment.join.advisor_note,
        },
        user_id: slot.fa_id,
      };
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Appointment Details has been updated!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      });
    },
    onJoinMyKNP(index) {
      let slot = this.customer.appointments[index];
      let slot_log = {
        slot_id: slot.id,
        type: "Customer Agree to Join MyKNP",
        raw: {
          date: "",
          time: "",
          service: "",
          sub_service: "",
          gen_enq_cat: "",
          gen_enq: "",
          advisor_note: this.update_appointment.join.advisor_note,
        },
        user_id: slot.fa_id,
      };
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Appointment Details has been updated!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      });
    },
    onJoinSDRS(index) {
      let slot = this.customer.appointments[index];
      let slot_log = {
        slot_id: slot.id,
        type: "Customer Agree to Join SDRS",
        raw: {
          date: "",
          time: "",
          service: "",
          sub_service: "",
          gen_enq_cat: "",
          gen_enq: "",
          advisor_note: this.update_appointment.join.advisor_note,
        },
        user_id: slot.fa_id,
      };
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Appointment Details has been updated!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      });
    },
    getServiceId(service) {
      switch (service) {
        case "Personalised Financial Plan (PFP)":
          return "17";
        case "Urus Help":
          return "1";
        case "Financial Skills":
          return "2";
        case "Financial Resolution":
          return "5";
        case "Financial Planning":
          return "12";
        case "Financial Scoring":
          return "14";
        case "Reverse Mortgage (Skim Saraan Bercagar - SSB)":
          return "15";
        case "Financial Advisory Services (MyKNP)":
          return "16";
        case "General Enquiry":
          return "18";

        default:
          break;
      }
    },
    handlePostDMPEnquiryChange(index) {
      if (this.update_appointment.details.post_dmp_enquiry == "Reschedule") {
        this.update_appointment.details.advisor_note =
          "<p><strong>Add on facility (1)</strong></p><p>Estimated DMP installment for the add on facility: RMxxx</p><p>FI's Name:</p><p>Type of facility: </p><p>Step up OR NO step up</p><p>Matrix : xx</p><p><br></p><p><strong>Add on facility (2)</strong></p><p>Estimated DMP installment for the add on facility: RMxxx</p><p>FI's Name:</p><p>Type of facility: </p><p>Step up OR NO step up</p><p>Matrix : xx</p><p><br></p><p><strong>Reduce/increase installment</strong></p><p>Estimated reduce/increase DMP installment: RMxx</p><p>Note : Estimated installment reduction are proposed for at least servicing interest</p><p><br></p><p><strong>FA Name: </strong></p><p><strong>Branch:</strong></p>";
      } else {
        this.update_appointment.details.advisor_note = "";
      }
    },
    handleDMPCheck() {
      if (this.update_appointment.join.dmpCheck) {
        this.update_appointment.join.advisor_note =
          "<p><strong>1. Total Estimated DMP installment</strong></p><p>RMxxx</p><br><p><strong>2. No of facilities to enroll in DMP</strong></p><p>5CC, 2PF, 1HL, 1HP</p><br><p><strong>3. M1/M4</strong></p><p>Step up OR NO step up</p><p>Estimated installment : RMxx</p><br><p><strong>4. M3</strong></p><p>FI's Name (MBSB &amp; AGRO)</p><p>Estimated Installment: RMxx</p><br><p><strong>5. M5</strong></p><p>FI's Name (MBSB &amp; AGRO)</p><p>Step up OR NO step up</p><p>Estimated Installment: RMxx</p><br><p><strong>6. M2</strong></p><p>FI's name &amp; facility</p><p>Reason for M2 proposal (pls explain briefly):</p><p>Estimated Installment: RMxx</p><br><p><strong>FA Name: </strong></p><p><strong>Branch : </strong></p>";
      } else {
        this.update_appointment.join.advisor_note = "";
      }
    },
  },
  async mounted() {
    this.init();
  },
});
